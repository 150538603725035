<template>
    <v-container fluid>
        <v-form @submit.prevent="getTracking('search')">
            <v-btn v-show="$vuetify.breakpoint.xsOnly"
                   :to="{ name: 'tracking.create' }"
                   color="indigo" dark fab fixed top
                   right class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer"
                                v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pb-0">
                        <v-row>
                            <v-col cols="12" sm="10">
                                <h3 class="headline pb-2" color="black">
                                    {{ $t("search_tracking") }}
                                </h3>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly"
                                   cols="12" sm="2" class="text-right">
                                <v-btn :to="{ name: 'tracking.create' }"
                                       dark color="indigo">
                                    {{ $t("create") }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider
                                    ref="client"
                                    rules=""
                                    v-slot="{ errors, valid }"
                                >
                                    <v-autocomplete
                                        v-model="client"
                                        :items="clientItems"
                                        :error="!valid"
                                        :error-messages="errors"
                                        :search-input.sync="clientSearching"
                                        item-text="name"
                                        item-value="id"
                                        prepend-icon="mdi-account-supervisor"
                                        :loading="loadingClients"
                                        :disabled="loading"
                                        @click="clearClients"
                                        :no-data-text="
                                            clientSearching
                                                ? $t('nothing_found_by', {
                                                      search: clientSearching,
                                                  })
                                                : $t(
                                                      'nothing_found_client_name'
                                                  )
                                        "
                                        :label="$t('client')"
                                        color="primary"
                                        return-object
                                        clearable
                                        autocomplete="off"
                                    >
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider
                                    ref="tracking_name"
                                    name="tracking_name"
                                    rules="min:3|max:255"
                                    v-slot="{ errors, valid }"
                                >
                                    <v-text-field
                                        v-model="tracking_name"
                                        type="text"
                                        :error-messages="errors"
                                        :disabled="loading"
                                        prepend-icon="mdi-arrow-decision"
                                        :label="$t('tracking_name')"
                                        color="primary"
                                        clearable
                                    ></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="py-4">
                        <v-row>
                            <v-col cols="12" sm="3" md="2">
                                <v-select
                                    :value="perPage"
                                    :items="perPageItems"
                                    :disabled="loading"
                                    :label="$t('items_per_page')"
                                    prepend-icon="mdi-counter"
                                    @input="
                                        perPage = options.itemsPerPage =
                                            Number($event)
                                    "
                                    hide-details
                                    dense
                                ></v-select>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="9"
                                md="10"
                                class="d-flex justify-center justify-sm-end align-self-center"
                            >
                                <v-btn
                                    type="submit"
                                    :disabled="invalid || loading"
                                    :block="$vuetify.breakpoint.xsOnly"
                                    color="primary"
                                >{{ $t("search") }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat light>
            <v-card-text class="px-0">
                <v-data-table
                    :headers="filteredHeaders"
                    :items="tracking"
                    :options.sync="options"
                    :page.sync="page"
                    :items-per-page="perPage"
                    :server-items-length="totalTracking"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDir"
                    :loading="loading"
                    :locale="lang"
                    @page-count="pageCount = options.pageCount = Number($event)"
                    :loading-text="$t('loading_please_wait')"
                    class="elevation-1"
                    hide-default-footer
                >
                    <template v-slot:item.name="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.name }}
                            {{ item.deleted ? " (" + $t("deleted") + ")" : "" }}
                        </div>
                    </template>
                    <template v-slot:item.active="{ item }">
                        {{ item.active ? $t("yes") : $t("no") }}
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    v-on="on"
                                    class="mr-2"
                                    @click="showTracking(item)"
                                    :title="$t('status_railways')"
                                >
                                    mdi-menu
                                </v-icon>
                            </template>
                            <span>{{ $t("tracking_container") }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    @click="editTracking(item)"
                                    icon
                                >
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t("edit") }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    :disabled="item.deleted"
                                    @click="deleteTracking(item)"
                                    icon
                                >
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{
                                    item.deleted ? $t("remotely") : $t("delete")
                                }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination
                    v-show="totalTracking > perPage"
                    v-model="page"
                    :total-visible="totalVisiblePag"
                    :length="pageCount"
                    :disabled="loading"
                ></v-pagination>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {mapGetters} from "vuex";
import debounce from "lodash/debounce";

export default {
    name: "Tracking",
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            sortBy: "id ",
            sortDir: true,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 5,
            totalTracking: 0,
            options: {},
            tracking_name: null,
            loading: false,
            tracking: [],
            client: null,
            clientItems: [],
            loadingClients: false,
            clientSearching: null,
            headers: [
                {
                    text: this.$t("client"),
                    align: "left",
                    sortable: false,
                    value: "client",
                },
                {
                    text: this.$t("name"),
                    align: "left",
                    sortable: true,
                    value: "name",
                },
                {
                    text: this.$t("actions"),
                    align: "center",
                    value: "action",
                    sortable: false,
                    width: 150,
                },
            ],
        };
    },
    computed: {
        ...mapGetters(["lang", "perPageItems"]),
        filteredHeaders() {
            return this.headers.filter((h) => !h.hide);
        },
    },
    watch: {
        options: {
            handler() {
                this.getTracking();
            },
            deep: false,
        },
        clientSearching: debounce(function (val) {
            if (val && !this.client) {
                this.getClients(val);
            }
        }, 500),
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage;
    },
    methods: {
        clearClients() {
            if (!this.client) {
                this.clientItems = [];
            }
        },
        async getClients(str) {
            if (str) {
                this.loadingClients = true;
                let params = {};
                params.filter = "search";
                if (str !== "undefined") {
                    params.client = str;
                }
                await this.$http
                    .get("admin/client", {
                        params: params,
                    })
                    .then((res) => {
                        this.clientItems = res.body.data;
                    })
                    .catch((err) => {
                        this.clientItems = [];
                        this.$toastr.error(
                            this.$t("failed_to_get_list_clients")
                        );
                    })
                    .finally((end) => {
                        this.loadingClients = false;
                    });
            }
        },
        editTracking(item) {
            this.$router.push({
                name: "tracking.edit",
                params: {
                    id: item.id,
                },
            });
        },
        showTracking(item) {
            this.$router.push({
                name: "tracking_container_form",
                params: {
                    tracking_id: item.id,
                },
            });
        },
        async getTracking(type) {
            this.loading = true;
            const {sortBy, sortDesc, page, itemsPerPage} = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? "asc" : "desc";
            }
            if (page !== undefined) {
                params.page = page;
            }
            if (type === 'search') {
                params.page = 1
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }

            if (this.tracking_name) {
                params.tracking_name = this.tracking_name;
            }
            if (this.client) {
                if (this.client.id) {
                    params.client_id = this.client.id;
                } else {
                    params.client_id = this.client;
                }
            }
            await this.$http
                .get("admin/tracking", {
                    params: params,
                })
                .then((res) => {
                    this.tracking = res.body.data;
                    this.page = res.body.meta.current_page;
                    this.totalTracking = res.body.meta.total;
                    this.pageCount = res.body.meta.last_page;
                })
                .catch((err) => {
                    this.tracking = [];
                    this.totalTracking = 0;
                    this.$toastr.error(this.$t("failed_to_get_list_trackings"));
                })
                .finally((end) => {
                    this.loading = false;
                });
        },
        async deleteTracking(item) {
            if (confirm(this.$t("delete_tracking"))) {
                var _this = this;
                this.loading = true;
                await this.$http
                    .delete(`admin/tracking/${item.id}`)
                    .then((res) => {
                        this.$toastr.success(
                            this.$t("tracking_has_been_deleted")
                        );
                        this.getTracking();
                    })
                    .catch((err) => {
                        this.$toastr.error(
                            this.$t("tracking_has_not_been_deleted")
                        );
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (
                                    hasOwnProperty.call(err.body.errors, prop)
                                ) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0],
                                        ]);
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message);
                            }
                        }
                    })
                    .finally((end) => {
                        this.loading = false;
                    });
            }
        },
    },
};
</script>
